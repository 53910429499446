// import { TranslationService } from "@services/translate";

import { IScreenControllerFuelingCreate } from "../connector/__types__/UIViewController";
import Button from "@shared/controls/Button/Button";
import SelectComponent from "@shared/controls/Select/Select";
import Input from "@shared/controls/Input/Input";
import { observer } from "mobx-react";
import { TranslationService } from "@services/translate";
import ToggleButton from "react-bootstrap/ToggleButton";
import ButtonGroup from "react-bootstrap/esm/ButtonGroup";
import { useControllers } from "@view/hooks/useControllers";
import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router";
import { Screens } from "@constants/screens";
import { styled } from "styled-components";

const FuelingCreateForm = observer((): JSX.Element => {
  const { UIViewController } = useControllers<{
    UIViewController: IScreenControllerFuelingCreate;
  }>();

  const navigate = useNavigate();

  const onFormSubmit = useCallback(async () => {
    try {
      await UIViewController.createFueling();

      navigate(Screens.Fuelings.path());
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    if (
      UIViewController.createFuelingState.volume <= 0 ||
      UIViewController.createFuelingState.coefficient <= 0
    ) {
      UIViewController.setWeight(0);
      return;
    }
    UIViewController.setWeight(
      Math.round(
        UIViewController.createFuelingState.volume *
          UIViewController.createFuelingState.coefficient
      )
    );
  }, [
    UIViewController.createFuelingState.volume,
    UIViewController.createFuelingState.coefficient,
  ]);

  return (
    <>
      <div className="custom-form">
        <div className="custom-field">
          <label>№ борта</label>
          <SelectComponent
            isClearable
            onFocus={() => UIViewController.fetchListOfVehicles()}
            onMenuScrollToBottom={() => UIViewController.fetchListOfVehicles()}
            options={UIViewController.listOfVehiclesMetadata.data.map(
              (vehicle, index) => ({
                id: vehicle.id,
                index,
                label: vehicle.number,
                number: vehicle.number,
                value: vehicle.id,
              })
            )}
            isMulti={false}
            onInputChange={UIViewController.setVehicleSearch}
            isLoading={UIViewController.isVehiclesLoading}
            value={
              UIViewController.createFuelingState.vehicle
                ? {
                    label: UIViewController.createFuelingState.vehicle.number,
                    value: UIViewController.createFuelingState.vehicle.id,
                  }
                : null
            }
            onChange={(vehicle: any) => UIViewController.setVehicle(vehicle)}
          />
        </div>
        <div className="custom-field">
          <label>№ топливного требования</label>
          <Input
            placeholder="Введите номер топливного требования"
            value={UIViewController.createFuelingState.number}
            type="string"
            onChange={(value) => UIViewController.setFuelingNumber(value)}
          />
        </div>
        <div className="custom-field">
          <label>Объем, л</label>
          <Input
            placeholder="Введите объем договора"
            value={UIViewController.createFuelingState.volume}
            type="number"
            onChange={(volume: number) => UIViewController.setVolume(volume)}
          />
        </div>
        <div className="custom-field">
          <label>Коэффициент</label>
          <Input
            placeholder="Введите коэффициент"
            value={UIViewController.createFuelingState.coefficient}
            type="number"
            onChange={(coefficient: number) =>
              UIViewController.setCoefficient(coefficient)
            }
            newValue={UIViewController.createFuelingState.coefficient}
          />
        </div>
        <div className="custom-field">
          <label>Вес, кг</label>
          <Input
            disabled={true}
            placeholder="Введите вес топлива"
            value={UIViewController.createFuelingState.weight}
            type="number"
            onChange={(weight: number) => UIViewController.setWeight(weight)}
            newValue={UIViewController.createFuelingState.weight}
          />
        </div>
        <div className="custom-field">
          <label>Тип заправки</label>
          <ButtonGroup>
            {[
              { text: "Основная", value: 1 },
              { text: "Перегон", value: 2 },
            ].map((radio, idx) => (
              <ToggleButton
                key={idx}
                id={`fuel-${idx}`}
                type="radio"
                variant="secondary"
                name="radio"
                value={radio.value}
                checked={
                  UIViewController.createFuelingState.fuelingType ===
                  radio.value
                }
                onChange={(event) =>
                  UIViewController.setFuelingType(
                    Number(event.currentTarget.value)
                  )
                }
              >
                {radio.text}
              </ToggleButton>
            ))}
          </ButtonGroup>
        </div>
        <div className="custom-field">
          <label>Регион</label>
          <SelectComponent
            onMenuScrollToBottom={() => UIViewController.fetchListOfRegions()}
            onFocus={() => UIViewController.fetchListOfRegions()}
            options={UIViewController.listOfRegionsMetadata.data.map(
              (region, index) => ({
                id: region.id,
                index,
                label: region.name,
                name: region.name,
                value: region.id,
              })
            )}
            isMulti={false}
            onInputChange={UIViewController.setRegionSearch}
            isClearable
            isLoading={UIViewController.isRegionsLoading}
            value={
              UIViewController.createFuelingState.region
                ? {
                    label: UIViewController.createFuelingState.region.name,
                    value: UIViewController.createFuelingState.region.id,
                  }
                : null
            }
            onChange={(region: any) => UIViewController.setRegion(region)}
          />
        </div>
        <div className="custom-field">
          <label>Поставщик</label>
          <SelectComponent
            isDisabled={!UIViewController.createFuelingState.region}
            onInputChange={UIViewController.setContractorSearch}
            isClearable
            onMenuScrollToBottom={() =>
              UIViewController.fetchListOfContractors()
            }
            onFocus={() => UIViewController.fetchListOfContractors(1, true)}
            options={UIViewController.listOfContractorsMetadata.data.map(
              (contractor, index) => ({
                id: contractor.id,
                index,
                label: contractor.name,
                name: contractor.name,
                value: contractor.id,
              })
            )}
            isLoading={UIViewController.isContractorsLoading}
            value={
              UIViewController.createFuelingState.contractor
                ? {
                    label: UIViewController.createFuelingState.contractor.name,
                    value: UIViewController.createFuelingState.contractor.id,
                  }
                : null
            }
            onChange={(contractor: any) =>
              UIViewController.setContractor(contractor)
            }
          />
        </div>
      </div>

      <div className="col align-center gap8">
        <Button
          text={TranslationService.t("txt_add")}
          type={"button"}
          className={"primary w-100"}
          size="small"
          loading={UIViewController.isFuelingCreationInProgress}
          onClick={onFormSubmit}
          disabled={
            !UIViewController.createFuelingState.contractor ||
            !UIViewController.createFuelingState.fuelingType ||
            !UIViewController.createFuelingState.number ||
            !UIViewController.createFuelingState.region ||
            !UIViewController.createFuelingState.vehicle ||
            !UIViewController.createFuelingState.volume ||
            !UIViewController.createFuelingState.weight
          }
        />
        {!UIViewController.createFuelingState.contractor ||
        !UIViewController.createFuelingState.fuelingType ||
        !UIViewController.createFuelingState.number ||
        !UIViewController.createFuelingState.region ||
        !UIViewController.createFuelingState.vehicle ||
        !UIViewController.createFuelingState.volume ||
        !UIViewController.createFuelingState.weight ? (
          <Hint>Заполните пустые поля</Hint>
        ) : null}
      </div>
    </>
  );
});

export default FuelingCreateForm;

const Hint = styled.p`
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
`;
