// import { TranslationService } from "@services/translate";
import {
  IUISceneController,
  TCreateScoreSceneParams,
  TCreateScoreSceneResult,
} from "../connector/UISceneController/types";
import Button from "@shared/controls/Button/Button";
import SelectComponent from "@shared/controls/Select/Select";
import { useEffect } from "react";
import Input from "@shared/controls/Input/Input";
import { observer } from "mobx-react";
import { useScene } from "@view/hooks/useScene/useScene";
import moment from "moment";
import "moment/locale/ru";
import { TranslationService } from "@services/translate";
import FilterComponent from "@shared/helpers/SideForm/SideForm";

const CreateScoreForm = observer((): JSX.Element => {
  const UISceneController =
    useScene<
      IUISceneController<TCreateScoreSceneResult, TCreateScoreSceneParams>
    >();
  moment.locale("ru");

  // Fetch initial data to the form fields
  useEffect(() => {
    UISceneController.fetchListOfContracts();
    UISceneController.fetchListOfRegions();
  }, []);

  useEffect(() => {
    if (
      UISceneController.createScoreState.amount <= 0 ||
      UISceneController.createScoreState.price <= 0
    ) {
      UISceneController.setWeight(0);
      return;
    }
    UISceneController.setWeight(
      Math.round(
        (UISceneController.createScoreState.amount /
          UISceneController.createScoreState.price) *
          1000
      )
    );
  }, [
    UISceneController.createScoreState.amount,
    UISceneController.createScoreState.price,
  ]);

  return (
    <FilterComponent title="Новый счет" onClose={UISceneController.closeScene}>
      <div className="custom-form">
        <div className="custom-field">
          <label>№ договора</label>
          <SelectComponent
            isClearable
            onMenuScrollToBottom={() =>
              UISceneController.fetchListOfContracts()
            }
            onFocus={() => UISceneController.fetchListOfContracts()}
            options={UISceneController.listOfContractsMetadata.data.map(
              (contract, index) => ({
                id: contract.id,
                index,
                label: contract.number + " (" + contract.contractor.name + ")",
                number: contract.number,
                value: contract.id,
              })
            )}
            onInputChange={UISceneController.setContractSearch}
            isLoading={UISceneController.isContractsLoading}
            // value={UISceneController.createScoreState.contract}
            value={
              UISceneController.createScoreState.contract
                ? {
                    label: UISceneController.createScoreState.contract.number,
                    value: UISceneController.createScoreState.contract.id,
                  }
                : null
            }
            onChange={(contract: any) =>
              UISceneController.setContract(contract)
            }
          />
        </div>
        <div className="custom-field">
          <label>№ счета</label>
          <Input
            placeholder="Введите номер счета"
            value={UISceneController.createScoreState.number}
            type="string"
            onChange={(value: string) =>
              UISceneController.setScoreNumber(value)
            }
          />
        </div>
        <div className="custom-field">
          <label>Дата</label>
          <Input
            placeholder="дд.мм.гг"
            value={UISceneController.createScoreState.deliveryDate.format(
              "YYYY-MM-DD"
            )}
            type="date"
            onChange={(value: string) =>
              UISceneController.setDeliveryDate(moment(value, "YYYY-MM-DD"))
            }
          />
        </div>
        <div className="custom-field">
          <label>Цена за тонну, ₽</label>
          <Input
            placeholder="Введите цену за тонну"
            value={UISceneController.createScoreState.price}
            type="number"
            onChange={(price: number) => UISceneController.setPrice(price)}
          />
        </div>
        <div className="custom-field">
          <label>Сумма, ₽</label>
          <Input
            placeholder="Введите сумму"
            value={UISceneController.createScoreState.amount}
            type="number"
            onChange={(amount: number) => UISceneController.setAmount(amount)}
          />
        </div>
        <div className="custom-field">
          <label>Вес топлива, кг</label>
          <Input
            disabled={true}
            placeholder="Введите вес топлива"
            value={UISceneController.createScoreState.weight}
            type="number"
            onChange={(weight: number) => UISceneController.setWeight(weight)}
            newValue={UISceneController.createScoreState.weight}
          />
        </div>

        <div className="custom-field">
          <label>Регион</label>
          <SelectComponent
            isDisabled={!UISceneController.createScoreState.contract}
            onMenuScrollToBottom={() => UISceneController.fetchListOfRegions()}
            onFocus={() => UISceneController.fetchListOfRegions()}
            options={UISceneController.listOfRegionsMetadata.data.map(
              (region, index) => ({
                id: region.id,
                index,
                label: region.name,
                name: region.name,
                value: region.id,
              })
            )}
            isMulti
            onInputChange={UISceneController.setRegionSearch}
            isClearable
            isLoading={UISceneController.isRegionsLoading}
            value={
              UISceneController.createScoreState.regions?.map(
                (region: any) => ({
                  id: region.id,
                  label: region.name,

                  name: region.name,
                  value: region.id,
                })
              ) || []
            }
            onChange={(region: any) => UISceneController.setRegions(region)}
          />
        </div>
      </div>

      <div className="row">
        <Button
          text={TranslationService.t("txt_add")}
          type={"button"}
          className={"primary"}
          size="small"
          loading={UISceneController.isScoreCreationInProgress}
          onClick={UISceneController.createScore}
        />
      </div>
    </FilterComponent>
  );
});

export default CreateScoreForm;
